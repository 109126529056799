import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = () => {
  const location = useLocation();

  useEffect(() => {
    // Add a slight delay to ensure the page has rendered
    setTimeout(() => {
      window.scrollTo(0, 0);  // Scroll to top when the route changes
    }, 0);  // Delay to ensure page rendering happens before scroll
  }, [location]);

  return null;  // No need to render anything
};

export default ScrollToTop;
