import logo from './logo.svg';
import './App.css';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Suspense, lazy } from 'react';
import ScrollToTop from './component/ScrollToTop';

// Lazy load components
const Home_header = lazy(() => import('./component/comp_emport').then(module => ({ default: module.Home_header })));
const Home_homeimage = lazy(() => import('./component/comp_emport').then(module => ({ default: module.Home_homeimage })));
const Home_aboutus = lazy(() => import('./component/comp_emport').then(module => ({ default: module.Home_aboutus })));
const Home_vision = lazy(() => import('./component/comp_emport').then(module => ({ default: module.Home_vision })));
const Home_machcount = lazy(() => import('./component/comp_emport').then(module => ({ default: module.Home_machcount })));
const Home_contact = lazy(() => import('./component/comp_emport').then(module => ({ default: module.Home_contact })));
const Home_footer = lazy(() => import('./component/comp_emport').then(module => ({ default: module.Home_footer })));

const Abo_header = lazy(() => import('./component/comp_emport').then(module => ({ default: module.Abo_header })));
const Abo_homeimage = lazy(() => import('./component/comp_emport').then(module => ({ default: module.Abo_homeimage })));
const Abo_aboutdes = lazy(() => import('./component/comp_emport').then(module => ({ default: module.Abo_aboutdes })));
const OurStory = lazy(() => import('./component/comp_emport').then(module => ({ default: module.OurStory })));
const Abo_history = lazy(() => import('./component/comp_emport').then(module => ({ default: module.Abo_history })));
const Abo_technology = lazy(() => import('./component/comp_emport').then(module => ({ default: module.Abo_technology })));
const Abo_team = lazy(() => import('./component/comp_emport').then(module => ({ default: module.Abo_team })));
const Abo_contact = lazy(() => import('./component/comp_emport').then(module => ({ default: module.Abo_contact })));
const Abo_footer = lazy(() => import('./component/comp_emport').then(module => ({ default: module.Abo_footer })));

const Pro_header = lazy(() => import('./component/comp_emport').then(module => ({ default: module.Pro_header })));
const Pro_homeimage = lazy(() => import('./component/comp_emport').then(module => ({ default: module.Pro_homeimage })));
const Pro_rails1 = lazy(() => import('./component/comp_emport').then(module => ({ default: module.Pro_rails1 })));
const Pro_products = lazy(() => import('./component/comp_emport').then(module => ({ default: module.Pro_products })));
const Pro_3dimages = lazy(() => import('./component/comp_emport').then(module => ({ default: module.Pro_3dimages })));
const Pro_grid2prod = lazy(() => import('./component/comp_emport').then(module => ({ default: module.Pro_grid2prod })));
const Pro_contact = lazy(() => import('./component/comp_emport').then(module => ({ default: module.Pro_contact })));
const Pro_footer = lazy(() => import('./component/comp_emport').then(module => ({ default: module.Pro_footer })));

const Man_header = lazy(() => import('./component/comp_emport').then(module => ({ default: module.Man_header })));
const Man_homeimage = lazy(() => import('./component/comp_emport').then(module => ({ default: module.Man_homeimage })));
const Man_machines = lazy(() => import('./component/comp_emport').then(module => ({ default: module.Man_machines })));
const Man_machcount = lazy(() => import('./component/comp_emport').then(module => ({ default: module.Man_machcount })));
const Man_contact = lazy(() => import('./component/comp_emport').then(module => ({ default: module.Man_contact })));
const Man_footer = lazy(() => import('./component/comp_emport').then(module => ({ default: module.Man_footer })));

const Qual_header = lazy(() => import('./component/comp_emport').then(module => ({ default: module.Qual_header })));
const Qual_homeimage = lazy(() => import('./component/comp_emport').then(module => ({ default: module.Qual_homeimage })));
const Qual_instruments = lazy(() => import('./component/comp_emport').then(module => ({ default: module.Qual_instruments })));
const Qual_contact = lazy(() => import('./component/comp_emport').then(module => ({ default: module.Qual_contact })));
const Qual_footer = lazy(() => import('./component/comp_emport').then(module => ({ default: module.Qual_footer })));

const Contact_header = lazy(() => import('./component/comp_emport').then(module => ({ default: module.Contact_header })));
const Contact_homeimage = lazy(() => import('./component/comp_emport').then(module => ({ default: module.Contact_homeimage })));
const Contact_us = lazy(() => import('./component/comp_emport').then(module => ({ default: module.Contact_us })));
const Contact_footer = lazy(() => import('./component/comp_emport').then(module => ({ default: module.Contact_footer })));

const Admin_Login = lazy(() => import('./component/comp_emport').then(module => ({ default: module.Admin_Login })));
const User_data = lazy(() => import('./component/comp_emport').then(module => ({ default: module.User_data })));

// Router Configuration
const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <Suspense fallback={<div></div>}>
        <ScrollToTop />
        <Home_header />
        <Home_homeimage />
        <Home_aboutus />
        <Home_vision />
        <Home_machcount />
        <Home_contact />
        <Home_footer />
      </Suspense>
    ),
  },
  {
    path: "/aboutus",
    element: (
      <Suspense fallback={<div></div>}>
        <ScrollToTop />
        <Abo_header />
        <Abo_homeimage />
        <Abo_aboutdes />
        <OurStory />
        <Abo_history />
        <Abo_technology />
        <Abo_team />
        <Abo_contact />
        <Abo_footer />
      </Suspense>
    ),
  },
  {
    path: "/products",
    element: (
      <Suspense fallback={<div></div>}>
        <ScrollToTop />
        <Pro_header />
        <Pro_homeimage />
        <Pro_rails1 />
        <Pro_products />
        <Pro_3dimages />
        <Pro_grid2prod />
        <Pro_contact />
        <Pro_footer />
      </Suspense>
    ),
  },
  {
    path: "/manufacturing",
    element: (
      <Suspense fallback={<div></div>}>
        <ScrollToTop />
        <Man_header />
        <Man_homeimage />
        <Man_machines />
        <Man_machcount />
        <Man_contact />
        <Man_footer />
      </Suspense>
    ),
  },
  {
    path: "/quality",
    element: (
      <Suspense fallback={<div></div>}>
        <ScrollToTop />
        <Qual_header />
        <Qual_homeimage />
        <Qual_instruments />
        <Qual_contact />
        <Qual_footer />
      </Suspense>
    ),
  },
  {
    path: "/contactus",
    element: (
      <Suspense fallback={<div></div>}>
        <ScrollToTop />
        <Contact_header />
        <Contact_homeimage />
        <Contact_us />
        <Contact_footer />
      </Suspense>
    ),
  },
  {
    path: "/users_data",
    element: (
      <Suspense fallback={<div></div>}>
        <ScrollToTop />
        <User_data />
      </Suspense>
    ),
  },
  {
    path: "/admin",
    element: (
      <Suspense fallback={<div></div>}>
        <ScrollToTop />
        <Admin_Login />
      </Suspense>
    ),
  },
]);

function App() {
  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
